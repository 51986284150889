import type { Option } from '../../../../../components/Dropdown/Dropdown';

export enum LandingSearchActionType {
  UpdateSearchInput = 'updateSearchInput',
  UpdateSearchResults = 'updateSearchResults',
  UpdateSelectedOption = 'updateSelectedOption',
}

type ActionUpdateSearchInput = {
  type: LandingSearchActionType.UpdateSearchInput;
  payload: {
    searchInput: string;
  };
};

type ActionUpdateSearchResults = {
  type: LandingSearchActionType.UpdateSearchResults;
  payload: {
    searchResults: Option[];
  };
};

type ActionUpdateSelectedOption = {
  type: LandingSearchActionType.UpdateSelectedOption;
  payload: {
    selectedOption?: Option;
  };
};

export type LandingSearchReducerAction =
  | ActionUpdateSearchInput
  | ActionUpdateSearchResults
  | ActionUpdateSelectedOption;
