import type { JSX, JSXElementConstructor, Key } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useIntersectionObserver } from '../../helpers/hooks/useIntersectionObserver';

export type KeyedItemProps = {
  key: Key;
};

export type LazyLoaderV2Props<ItemProps extends KeyedItemProps> = {
  itemsProps: ItemProps[];
  component: JSXElementConstructor<Omit<ItemProps, 'key'>>;
  initialDisplayCount: number;
  overscanDisplayCount: number;
  increment: number;
};

export function LazyLoaderV2<ItemProps extends KeyedItemProps>({
  itemsProps,
  component: Component,
  initialDisplayCount,
  overscanDisplayCount = 0,
  increment,
}: LazyLoaderV2Props<ItemProps>): JSX.Element {
  const [count, setCount] = useState(initialDisplayCount);

  useEffect(() => {
    setCount(initialDisplayCount + overscanDisplayCount);
  }, [initialDisplayCount, overscanDisplayCount]);

  const onIntersect = useCallback(() => {
    setCount((value) => value + increment);
  }, [increment]);

  const { refCallback } = useIntersectionObserver({
    threshold: 0,
    onIntersect,
  });

  return (
    <>
      {itemsProps.slice(0, count).map(({ key, ...rest }, index) => (
        <Component
          key={key}
          {...rest}
          ref={
            index === count - 1 - overscanDisplayCount &&
            count < itemsProps.length
              ? refCallback
              : undefined
          }
        />
      ))}
    </>
  );
}
