import type { JSX } from 'react';
import type { Option } from '../../../../components/Dropdown/Dropdown';
import { Dropdown } from '../../../../components/Dropdown/Dropdown';
import styles from './LandingSearch.css';

type LandingSearchProps = {
  landingSearchInput: string;
  handleChange: (value: string) => void;
  options: Option[];
  placeholder: string;
  selectOption: (option: Option) => void;
};

function LandingSearch({
  landingSearchInput,
  handleChange,
  options,
  placeholder,
  selectOption,
}: LandingSearchProps): JSX.Element {
  return (
    <div className={styles.search}>
      <Dropdown
        options={options}
        onClick={selectOption}
        isInputError={false}
        placeholder={placeholder}
        onChange={(_, value) => handleChange(value)}
        onFocus={(_, value) => handleChange(value)}
        inputClassname={styles.search__input}
        inputValue={landingSearchInput}
        type="searchChannels"
      />
    </div>
  );
}

LandingSearch.displayName = 'LandingSearch';

export default LandingSearch;
