import { Template } from '@canalplus/sdk-hodor';
import type { Tracking } from '@dce-front/hodor-types/api/v2/common/dto/tracking/definitions';
import type { JSX } from 'react';
import ErrorTemplate from '../../../components/Error/ErrorTemplate';
import { MetaTagsTemplate } from '../../../components/MetaTags/MetaTagsTemplate';
import Spinner from '../../../components/Spinner/Spinner';
import { useInvariantSelector } from '../../../helpers/hooks/useInvariantSelector';
import { useQueryTemplate } from '../../../helpers/hooks/useQueryTemplate/useQueryTemplate';
import { FocusManager } from '../../../helpers/oneNavigation/FocusManager';
import type { FetchDetails } from '../../../services/types';
import { FetchRequestTypes } from '../../../services/types';
import {
  featIdentityV5Selector,
  isIOsSelector,
} from '../../../store/slices/application-selectors';
import type { LandingV5State } from '../../../templates/LandingV5/data/formatter';
import { getFormattedLandingUrl } from '../../../templates/LandingV5/data/formatter';
import { LandingSearchProvider } from './LandingSearch/store/context';
import LandingV5 from './LandingV5';
import { LandingContextProvider } from './LandingV5Context';

export type LandingV5ContainerProps = {
  disableMetaUpdate?: boolean;
  onClickParameters?: FetchDetails['onClickParameters'];
  focusManager?: FocusManager;
  url?: string;
  isMainTemplate?: boolean;
};

function LandingV5Container({
  disableMetaUpdate = false,
  onClickParameters,
  focusManager,
  url,
  isMainTemplate,
}: LandingV5ContainerProps): JSX.Element | null {
  const isIOS = useInvariantSelector(isIOsSelector);
  const urlFormatted = url ? getFormattedLandingUrl(url) : undefined;
  const featIdentityV5 = useInvariantSelector(featIdentityV5Selector);

  const [{ isLoading, data, error }] = useQueryTemplate<
    LandingV5State & { tracking: Tracking }
  >(
    urlFormatted,
    {
      onClickParameters,
      template: FetchRequestTypes.LandingV5,
      isMainTemplate,
    },
    {
      refetchOnMount: false,
      enabled: !!urlFormatted,
    },
    { featIdentityV5, isIOS },
  );

  if (isLoading) {
    return <Spinner />;
  }

  if (error || !urlFormatted) {
    return <ErrorTemplate error={error} />;
  }

  if (!data) {
    return null;
  }

  const isEPGStrateExist = !!data.strates?.some(
    (strate) => strate.type === Template.ElectronicProgramGuide,
  );
  const isSearchEnabled = isEPGStrateExist;

  const LandingV5Component = (
    <LandingContextProvider>
      <MetaTagsTemplate
        data={data}
        disableMetaUpdate={disableMetaUpdate}
        enableAlternateLinksUpdate
      />
      <LandingV5
        data={data}
        isSearchEnabled={isSearchEnabled}
        focusManager={focusManager}
      />
    </LandingContextProvider>
  );

  return isSearchEnabled ? (
    <LandingSearchProvider>{LandingV5Component}</LandingSearchProvider>
  ) : (
    LandingV5Component
  );
}

export default LandingV5Container;
