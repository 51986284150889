import classNames from 'classnames/bind';
import styles from '../../../components/IdentityCover/IdentityCover.css';
import type { CoverV5Data } from '../../../components/IdentityCover/types';

const cx = classNames.bind(styles);

function getOverlappingClassnameWithCoverV5(
  cover: CoverV5Data,
): string | undefined {
  if (!cover.image) {
    return;
  }

  if (cover.urlLogoType || cover.urlLogoChannel) {
    return cx('identityV5--overlapping__logo');
  }

  return cx('identityV5--overlapping');
}

export default getOverlappingClassnameWithCoverV5;
