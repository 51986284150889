import type { QueryKey } from '@tanstack/react-query';
import { universalService } from '../../../services/UniversalService';
import { FetchRequestTypes } from '../../../services/types';
import type { FetchTemplateParameters } from '../../types';

type FetchContentRowPersoParameters = FetchTemplateParameters & {
  profileId?: number;
  queryKey: QueryKey;
};

export async function fetchContentRowPerso({
  queryKey,
  hodorSdkConfig,
  onClickParameters,
  queryClient,
  URLPage,
  profileId,
}: FetchContentRowPersoParameters): Promise<void> {
  await queryClient.prefetchQuery({
    queryKey, // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      universalService({
        url: URLPage,
        fetchDetails: {
          hodorSdkConfig,
          onClickParameters,
          template: FetchRequestTypes.ContentRowPersoContainer,
          options: { profileId },
        },
        formatterProps: {},
      }),
  });
}
