import { Template } from '@canalplus/sdk-hodor';
import { useQueryClient, type QueryKey } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { LiveChannels } from '../../../constants/liveTv';
import { StrateMode } from '../../../constants/strates';
import { getActualQueryKeyFromUrl } from '../../../helpers/hooks/useGenerateQueryKey';
import { getFormattedLiveUrl } from '../../../helpers/liveTV/liveTV-helper';
import { hodorSdkConfigSelector } from '../../../store/slices/application-selectors';
import { accessibleEpgIDsSelector } from '../../../store/slices/user-selectors';
import { fetchContentRowLiveTv } from '../../ContentRowLive/data/fetchContentRowLiveTv';
import type { StrateV5 } from '../data/formatter';

/**
 * This hook will prefetch data from content row liveTv inside a Landing page at the top of page
 * it will avoid us to load data when mounting content row liveTv component
 * @param strates StrateV5
 */
export function usePrefetchContentRowLiveTv(strates: StrateV5[] = []) {
  const hodorSdkConfig = useSelector(hodorSdkConfigSelector);
  const epgIDs = useSelector(accessibleEpgIDsSelector);
  const queryClient = useQueryClient();

  const liveStrates = useMemo(
    () =>
      strates?.filter(
        (strate) =>
          strate.type === Template.ContentRow &&
          strate.strateMode === StrateMode.LiveTv,
      ),
    [strates],
  );

  useEffect(() => {
    function prefetchContentRow(strate: StrateV5) {
      const onClickParameters = strate?.paging?.parameters;
      const URLPage = strate?.paging?.URLPage;

      if (!hodorSdkConfig || !URLPage || !epgIDs.length) {
        return;
      }

      const formattedUrl = getFormattedLiveUrl(
        URLPage,
        epgIDs,
        LiveChannels.NB_DEFAULT_PAGED_TO_REQUEST,
      );

      const actualQueryKey: QueryKey = getActualQueryKeyFromUrl(formattedUrl);

      return fetchContentRowLiveTv({
        queryKey: actualQueryKey,
        hodorSdkConfig,
        onClickParameters,
        queryClient,
        URLPage: formattedUrl,
      });
    }

    Promise.all(liveStrates.map(prefetchContentRow));
  }, [liveStrates, queryClient, hodorSdkConfig, epgIDs]);
}
