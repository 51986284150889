import type { QueryKey } from '@tanstack/react-query';
import { universalService } from '../../../services/UniversalService';
import { FetchRequestTypes } from '../../../services/types';
import type { FetchTemplateParameters } from '../../types';

type FetchContentRowLiveTvParameters = FetchTemplateParameters & {
  queryKey: QueryKey;
};

export async function fetchContentRowLiveTv({
  queryKey,
  hodorSdkConfig,
  onClickParameters,
  queryClient,
  URLPage,
}: FetchContentRowLiveTvParameters): Promise<void> {
  await queryClient.prefetchQuery({
    queryKey, // eslint-disable-line @tanstack/query/exhaustive-deps
    queryFn: () =>
      universalService({
        url: URLPage,
        fetchDetails: {
          hodorSdkConfig,
          onClickParameters,
          template: FetchRequestTypes.ContentRowLive,
        },
        formatterProps: {},
      }),
  });
}
