import type { PosterProps } from '@canalplus/mycanal-sharedcomponent';
import { Poster } from '@canalplus/mycanal-sharedcomponent';
import type { JSX } from 'react';
import { useSelector } from 'react-redux';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import {
  imageQualityPercentageSelector,
  maxPosterRatioSelector,
} from '../../store/slices/application-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../store/slices/user-selectors';

export function PosterConnected(
  props: Omit<PosterProps, 'imageQualityPercentage' | 'maxImageRatio'>,
): JSX.Element {
  const imageQualityPercentageBySettings = useSelector(
    imageQualityPercentageBySettingsSelector,
  );
  const imageQualityPercentage = useInvariantSelector(
    imageQualityPercentageSelector,
  );
  const maxImageRatio = useInvariantSelector(maxPosterRatioSelector);

  return (
    <Poster
      {...props}
      imageQualityPercentage={
        imageQualityPercentageBySettings || imageQualityPercentage
      }
      maxImageRatio={maxImageRatio}
    />
  );
}
