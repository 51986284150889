import type { ContentRowHeader } from '@canalplus/mycanal-sharedcomponent';
import { Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import type { ApiV2PageTracking } from '@dce-front/hodor-types/api/v2/page/dtos/definitions';
import type { StoreBinder } from '@dce-front/one-navigation';
import classNames from 'classnames/bind';
import {
  forwardRef,
  memo,
  useImperativeHandle,
  useMemo,
  useRef,
  type JSX,
} from 'react';
import { useSelector } from 'react-redux';
import LoadableBanner from '../../../../components/Banner/LoadableBanner';
import LoadableCarrouselTemplate from '../../../../components/Carrousel/LoadableCarrouselTemplate';
import { useIsFrom } from '../../../../components/Page/useIsFrom';
import LoadablePlainTextHtmlTemplate from '../../../../components/PlainTextHtml/LoadablePlainTextHtmlTemplate';
import SkipLink from '../../../../components/SkipLink/SkipLink';
import { StrateMode } from '../../../../constants/strates';
import { useBinderMiddleware } from '../../../../helpers/hooks/useBinderMiddleware';
import { useInvariantSelector } from '../../../../helpers/hooks/useInvariantSelector';
import { useOffsetTop } from '../../../../helpers/hooks/useOffsetTop';
import { FocusManager } from '../../../../helpers/oneNavigation/FocusManager';
import {
  getMiddlewareContentGridTop,
  getMiddlewareStratePersoTop,
  getMiddlewareStrateTop,
} from '../../../../helpers/oneNavigation/middleware';
import { useTranslation } from '../../../../lang';
import type { FetchDetails } from '../../../../services/types';
import {
  applicationResizeSelector,
  getFeatureTogglePromotionBanner,
  isIOsSelector,
} from '../../../../store/slices/application-selectors';
import { hasCoverSelector } from '../../../../store/slices/page-selectors';
import LoadableContentGrid from '../../../ContentGrid';
import LoadableContentGridPerso from '../../../ContentGridPerso';
import LoadableContentRowLiveTv from '../../../ContentRowLive';
import LoadableContentRowPerso from '../../../ContentRowPerso';
import LoadableContentRowStandard from '../../../ContentRowStandard';
import LoadableElectronicProgramGuide from '../../../ElectronicProgramGuide';
import LoadableFaq from '../../../Faq';
import type { StrateV5 } from '../../data/formatter';
import LoadablePromotionStrate from '../PromotionStrate/LoadablePromotionStrate';
import getFormattedPromotionStrate from '../PromotionStrate/data/getFormattedPromotionStrate';
import styles from './Strate.css';
import { StrateLoader } from './StrateLoader';

const cx = classNames.bind(styles);

export const STRATE_TYPES_WITHOUT_SKIP_LINK = [
  'banner',
  'promotionBanner',
  'plainTextHTML',
] as Template[] satisfies Template[];

export type StrateProps = {
  /**
   * Whether current strate is one in a series of Strate items.
   *
   * When `true`, a skip link to the next strate is displayed.
   */
  hasNextStrate: boolean;
  /** The anchor id of the Strate to navigate to.  */
  hrefNextStrate?: string;
  /** The unique identifier of the strate. */
  id: string;
  /** Whether the strate is the first one in a series of Strate items. */
  isFirstStrate: boolean;
  /** Object containing the Strate data keys. */
  strate: StrateV5;
  /** Callback triggered when Strate is focused. */
  focusManager?: FocusManager;
  /** The binderId is used in focus restoration. */
  binderId?: StoreBinder['id'];
  /** Page tracking object. */
  tracking: ApiV2PageTracking;
  /** Data test id. */
  dataTestId?: string;
};

/**
 * Displays a horizontal strate of content (Carousel, ContentRow, etc.),
 * with an optional skip link to move past blocks of strate contents when using the keyboard.
 */
const Strate = forwardRef<HTMLDivElement, StrateProps>(function Strate(
  {
    id,
    hasNextStrate = false,
    hrefNextStrate,
    isFirstStrate,
    strate: {
      button,
      contents,
      context,
      displayParameters,
      paging,
      perso,
      strateMode,
      subtitle,
      title,
      type,
      URLProgramGuide,
      css,
      html,
      js,
    },
    tracking,
    focusManager,
    binderId,
    dataTestId,
  }: StrateProps,
  ref,
): JSX.Element | null {
  const { t } = useTranslation();
  const isIOS = useInvariantSelector(isIOsSelector);
  const resizeMode = useInvariantSelector(applicationResizeSelector);
  const isFromDetail = useIsFrom(Template.DetailPage);
  const hasCover = useSelector(hasCoverSelector);
  const strateRef = useRef<HTMLDivElement>(null);
  const offsetTop = useOffsetTop(
    strateRef.current,
    type === Template.ContentGrid &&
      strateMode !== StrateMode.Perso &&
      $_BUILD_RENDERMODE_CSR,
  );

  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(
    ref,
    () => strateRef.current,
    [],
  );

  const { URLPage, parameters: onClickParameters } = paging || {};
  const { anchorIndex: itemOffset, titleDisplayMode } = displayParameters || {};

  const header = useMemo<ContentRowHeader>(
    () => ({ title, subtitle, ...(!$_BUILD_RENDERMODE_CSR && { button }) }),
    [title, subtitle, button],
  );

  const hasPromotionBanner = useInvariantSelector(
    getFeatureTogglePromotionBanner,
  );

  const middlewareStratePersoTop = useBinderMiddleware(
    getMiddlewareStratePersoTop,
  );
  const middlewareStrateTop = useBinderMiddleware(getMiddlewareStrateTop);
  const middlewareContentGridTop = useBinderMiddleware(
    getMiddlewareContentGridTop,
  );

  const strateContentProps = {
    displayParameters,
    header,
    onClickParameters,
    focusManager,
    binderId,
    fallback: <StrateLoader />,
  } satisfies Partial<StrateProps> & {
    header: ContentRowHeader;
    displayParameters: StrateV5['displayParameters'];
    onClickParameters?: FetchDetails['onClickParameters'];
    fallback: JSX.Element;
  };

  const getStrateContent = () => {
    switch (type) {
      case Template.Banner:
        return contents ? (
          <LoadableBanner
            contents={contents}
            focusManager={focusManager}
            binderId={binderId}
            resizeMode={resizeMode}
            fallback={<StrateLoader />}
          />
        ) : null;

      case Template.Carrousel:
        return contents ? (
          <LoadableCarrouselTemplate
            contents={contents}
            displayParameters={displayParameters}
            focusManager={focusManager}
            binderId={binderId}
            fallback={<StrateLoader />}
          />
        ) : null;

      case Template.ContentGrid: {
        if (strateMode === StrateMode.Perso && URLPage) {
          return (
            <LoadableContentGridPerso
              {...strateContentProps}
              persoType={perso}
              url={URLPage}
            />
          );
        }

        // Enable scroll to top middleware on first strate, when landing is not inside a detailV5 page
        const showMiddlewareWithScrollToTop = isFirstStrate && !isFromDetail;

        return contents ? (
          <LoadableContentGrid
            {...strateContentProps}
            contents={contents}
            paging={paging}
            url={URLPage}
            {...(showMiddlewareWithScrollToTop && {
              middleware: middlewareContentGridTop,
            })}
            isVirtualization
            offsetTop={offsetTop}
          />
        ) : null;
      }

      case Template.ContentRow: {
        // Enable scroll to top middleware on first strate, when landing is not inside a detailV5 page
        const showMiddlewareWithScrollToTop = isFirstStrate && !isFromDetail;
        if (strateMode === StrateMode.Perso) {
          return URLPage ? (
            <LoadableContentRowPerso
              {...strateContentProps}
              tracking={tracking}
              trackingContext={context}
              url={URLPage}
              {...(showMiddlewareWithScrollToTop && {
                middleware: middlewareStratePersoTop,
              })}
            />
          ) : null;
        } else if (strateMode === StrateMode.LiveTv) {
          return URLPage ? (
            <LoadableContentRowLiveTv
              {...strateContentProps}
              itemOffset={itemOffset}
              url={URLPage}
              trackingContext={context}
              {...((showMiddlewareWithScrollToTop ||
                (hasCover && !isFromDetail)) && {
                middleware: middlewareStrateTop,
              })}
            />
          ) : null;
        } else {
          return contents ? (
            <LoadableContentRowStandard
              {...strateContentProps}
              contents={contents}
              itemOffset={itemOffset}
              URLNextPage={URLPage}
              {...((showMiddlewareWithScrollToTop ||
                (hasCover && !isFromDetail)) && {
                middleware: middlewareStrateTop,
              })}
            />
          ) : null;
        }
      }

      case Template.Faq: {
        return contents && !$_BUILD_RENDERMODE_CSR ? (
          <LoadableFaq contents={contents} title={title} />
        ) : null;
      }

      case Template.ElectronicProgramGuide:
        return URLProgramGuide ? (
          <LoadableElectronicProgramGuide url={URLProgramGuide} />
        ) : null;

      case Template.PlainTextHtml:
        return (
          <LoadablePlainTextHtmlTemplate
            title={title}
            html={html}
            style={css}
            script={js}
          />
        );

      case Template.PromotionBanner: {
        if (!hasPromotionBanner) {
          return null;
        }
        const formattedContent = getFormattedPromotionStrate(
          {
            displayParameters,
            contents,
            type,
          },
          isIOS,
        );

        if (!formattedContent) {
          return null;
        }

        return (
          <LoadablePromotionStrate
            content={formattedContent.content}
            promotionType={formattedContent?.promotionType}
            focusManager={focusManager}
            fallback={<StrateLoader />}
          />
        );
      }

      default:
        return null;
    }
  };

  const strateContent = getStrateContent();

  if (!strateContent) {
    return null;
  }

  const isStrateSkipLinkCompatible =
    !!type && !STRATE_TYPES_WITHOUT_SKIP_LINK.includes(type as Template);
  const showSkipLink =
    !isFromDetail &&
    !$_BUILD_RENDERMODE_CSR &&
    isStrateSkipLinkCompatible &&
    (isFirstStrate ? hasNextStrate : true);

  return (
    <div
      className={cx('strate', {
        'strate--no-padding': titleDisplayMode === TitleDisplayMode.All,
      })}
      id={id}
      data-testid={dataTestId}
      ref={strateRef}
    >
      {showSkipLink && (
        <SkipLink
          href={hrefNextStrate}
          label={hasNextStrate ? 'nextCategory' : 'lastCategory'}
          ariaLabel={
            title
              ? t('A11y.nextCategoryAriaLabel', { category: title })
              : undefined
          }
          className={cx('strate__skip-link')}
        />
      )}
      {strateContent}
    </div>
  );
});

export default memo(Strate);
