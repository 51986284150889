import { useEffect, useState } from 'react';
import { useIsClient } from 'usehooks-ts';

/**
 * This hook return the offset top of html element, updated when there is
 * some changes on DOM (through Mutation observer)
 * @param el HTMLElement
 * @param enabled boolean
 */
export const useOffsetTop = (el: HTMLElement | null, enabled = false) => {
  const [offsetTop, setOffsetTop] = useState(el?.offsetTop ?? 0);
  const isClient = useIsClient();

  useEffect(() => {
    if (!enabled || !el || !isClient) {
      return;
    }

    setOffsetTop(el.offsetTop);

    const observer = new MutationObserver(() => {
      setOffsetTop(el.offsetTop);
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, [el, enabled, isClient]);

  return offsetTop;
};
