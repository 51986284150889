import { getCleanedString } from '@dce-front/onewebapp-utils';
import type { JSX } from 'react';

export type SchemaData = {
  title: string;
  hostname: string;
  pathnames: string[];
};

export type SchemaListItemProps = {
  schemaData: SchemaData;
};

export function SchemaListItem({
  schemaData,
}: SchemaListItemProps): JSX.Element {
  const { title, hostname, pathnames } = schemaData;
  const name = getCleanedString(title);

  const itemListElement = pathnames.map(
    (pathname, index) => `{
      "@type": "ListItem",
      "position": ${index + 1},
      "url": "https://${hostname}${pathname}"
    }`,
  );

  const DATA = `{
    "@context": "http://schema.org",
    "@type": "ItemList",
    "name": "${name}",
    "itemListElement": [${itemListElement}]
}`;

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: DATA }} // eslint-disable-line react/no-danger
    />
  );
}
