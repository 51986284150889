import { DIMENSIONS } from '@dce-front/onewebapp-dive-utils';
import type { JSX } from 'react';
import CoverImage from '../../CoverImage/CoverImage';
import type { CoverDeprecatedData } from '../types';
import styles from './CoverDeprecated.css';

export interface ICoverProps {
  cover: CoverDeprecatedData;
}

function CoverDeprecated({ cover }: ICoverProps): JSX.Element {
  const coverImageLabel = cover?.label;
  const coverImageTitle = cover?.onClick?.displayName;

  return (
    <section className={styles['cover--deprecated']}>
      <CoverImage
        dimensions={DIMENSIONS.COVER_IMAGE}
        image={{ default: cover.image }}
        onClickData={cover}
        title={coverImageTitle || coverImageLabel}
      />
    </section>
  );
}

export default CoverDeprecated;
